



















































import { addMajor, queryMajor } from '@/api/costQuotaLibrary/projectMajor';
import { FormModel } from 'ant-design-vue';
import Vue from 'vue';
import { MajorType } from '@/interface/costQuotaLibrary';
import { mapGetters } from 'vuex';
export default Vue.extend({
  name: 'newAdd',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  data() {
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 12 },
      form: {
        majorname: '',
        agencycode: '',
      },
      majorList: [] as Partial<MajorType>[],
      rules: {
        parentid: [
          {
            required: true,
            message: '请选择上级分类',
            trigger: 'change',
          },
        ],
        majorname: [
          {
            required: true,
            message: '请输入创建的分类名称',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  watch: {
    visible: function (val, oldVal) {
      if (val) {
        this.form = {
          majorname: '',
          agencycode: '',
        };
        this.getMajorList();
      }
    },
  },
  methods: {
    getMajorList() {
      queryMajor().then(({ status, result }) => {
        if (status !== 200 && !result) return;
        this.majorList = result;
      });
    },
    cancel() {
      (this.$refs.ruleForm as FormModel).resetFields();
      this.$emit('update:visible', false);
    },
    save() {
      (this.$refs.ruleForm as FormModel).validate((valid) => {
        if (!valid) return;
        this.form.agencycode = this.userInfo?.agencyCode;
        addMajor(this.form).then(({ status, result }) => {
          if (status === 200 && result) {
            this.$emit('callback');
            this.cancel();
            if (result === '添加成功') {
              this.$message.success(result);
            } else {
              this.$message.error(result);
            }
            return;
          }
          this.$message.error(result);
        });
      });
    },
  },
});

/*
 * @Descripttion:
 * @Author: renmingming
 * @Date: 2022-06-13 15:00:22
 * @LastEditors: renmingming
 * @LastEditTime: 2022-06-21 16:30:06
 */
import http from '@/utils/http';
import { MajorType } from '@/interface/costQuotaLibrary';

/**
 * 获取所有一级专业
 * @returns
 */
export function queryMajor() {
  return http.get<Partial<MajorType>[]>(
    '/cocc-quotalibrary-resource/1/qyProjectMajor/queryMajor'
  );
}
/**
 * 根据企业编码获取该企业下所有专业
 * @param agencyCode
 * @returns
 */
export function queryEnterpriseMajor(agencyCode) {
  return http.get<MajorType>(
    `/cocc-quotalibrary-resource/1/qyProjectMajor/queryEnterpriseMajor/${agencyCode}`
  );
}

/**
 * 删除二级专业
 * @param sequencenbr
 * @returns
 */
export function deleteMajor(sequencenbr) {
  return http.delete<string>(
    `/cocc-quotalibrary-resource/1/qyProjectMajor/delMajor/${sequencenbr}`
  );
}

/**
 * 修改二级专业名字
 * @param params {sequencenbr: '二级id', newName: ''}
 * @returns
 */
export function updateMajorName(params: {
  sequencenbr: string;
  newName: string;
  agencycode: string;
  parentid: string;
}) {
  return http.post<string>(
    '/cocc-quotalibrary-resource/1/qyProjectMajor/changeMajorName',
    params
  );
}

/**
 *
 * @param params agencycode企业编码 majorname 二级专业名称 parentid一级专业id
 * @returns
 */
export function addMajor(params: {
  agencycode: string;
  majorname: string;
  parentid?: string;
}) {
  return http.post<string>(
    '/cocc-quotalibrary-resource/1/qyProjectMajor/addMajor',
    params
  );
}

/**
 * 方案移动
 * @param params {sourceMajorNbr：来源专业id, targetMajorNbr: 目标专业id}
 * @returns
 */
export function moveMajor(params: {
  sourceMajorNbr: string;
  targetMajorNbr: string;
}) {
  return http.put<string>(
    '/cocc-quotalibrary-resource/1/qyStlBillDe/move/bill/major',
    params
  );
}

/**
 * 方案部分移动
 * @param params
 * @returns
 */
export function movePartMajor(params) {
  return http.put<string>(
    '/cocc-quotalibrary-resource/1/qyStlBillDe/move/part/bill/major',
    params
  );
}

/**
 * 查询企业下第一个有清单的二级专业
 * @param agencyCode
 */
export function getFirstDefMajor(agencyCode) {
  return http.get(
    `/cocc-quotalibrary-resource/1/qyProjectMajor/getFirstDefMajor/${agencyCode}`
  );
}

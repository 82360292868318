import { render, staticRenderFns } from "./newAdd.vue?vue&type=template&id=ffdd05c2&scoped=true&"
import script from "./newAdd.vue?vue&type=script&lang=ts&"
export * from "./newAdd.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ffdd05c2",
  null
  
)

export default component.exports